export const host = "https://be-loadtripping-chat.mtechub.com";
export const loginRoute = `${host}/user/login`;
export const registerRoute = `${host}/user/register`;
export const uploadImageRoute = `${host}/upload-image`;

export const logoutRoute = `${host}/user/logout`;
export const updateUsernameRoute = `${host}/user/updateUsername`;

export const verifyEmailRoute = `${host}/user/verifyEmail`;
export const getUserOTPUniqID = `${host}/user/getUserByUniqId`;

export const allUsersRoute = `${host}/user/getAllUsers`;
export const sendMessageRoute = `${host}/messages/createMessage`;
export const recieveMessageRoute = `${host}/messages/getmsg`;
export const readMessagesRoute = `${host}/messages/readMessages`;
